.services-title{
    font-size: 7vw;
    white-space: nowrap;
}
.services-text{
    font-size: 1.5vw;
}
.services-container{
    height: 700px;
}
@media (min-width: 2220px) {
    .services-text{
        font-size: 33.3px;
    }
}
@media (max-width: 1111px) {
    .services-text{
        font-size: 2vw;
    }
}

@media (min-width: 1375px) {
    .services-title{
        font-size: 96.25px;
    }
}
.services-button{
    margin-top: 40px;
    border-radius: 40px !important;
    font-size: 25px !important;
}
@media (max-width: 767px){
.services-content{
    position: relative;
    bottom: 100px;
}
.services-text{
    margin-bottom: 40px;
    font-size:4vw;
}
.services-container{
    height: 880px;
}
.services-button{
    font-size: 6vw !important;
}
}
